import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import _ from 'lodash';

const MultipleSelection = ({ id, label, value, options, helperText, required, allowOtherValue, onChange }) => {
  const choices = _.union(options, options);

  const handleChange = (e, value) => {
    onChange && onChange(id, value);
  };

  return (
    <Autocomplete
      multiple
      id={id}
      value={value || []}
      options={choices}
      freeSolo={allowOtherValue}
      onChange={handleChange}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip key={option} variant="outlined" label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          helperText={helperText}
          required={required}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  );
};

export default MultipleSelection;
