import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import MUIDataTable from 'mui-datatables';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Layout from './Layout';

import { getChurchList } from '../store/actions/church';

const ChurchList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { items } = useSelector((state) => state.church);

  useEffect(() => {
    dispatch(getChurchList());
  }, [dispatch]);

  let data = [];
  items.forEach((item) => {
    data.push([
      item.id,
      item.id,
      item.status,
      item.name,
      item.church_name_translation,
      item.denomination,
      item.address,
      item.city,
      item.province,
      item.postal_code,
      item.languages,
      item.url_photo,
      item.website,
      item.phone,
      item.email,
      item.contact_person_name,
      item.contact_person_email,
      item.decision_maker,
      item.newcomer_group,
      item.comments,
      item.latitude,
      item.longitude,
      item.training_onboard_date,
      item.enrollment_approval_date,
      item.approver_name,
      item.notes,
      item.portfolio_manager,
      item.submitter_email,
      item.date_created,
      item.date_modified,
    ]);
  });

  const columns = [
    {
      options: {
        filter: false,
        download: false,
        print: false,
        searchable: false,
        sort: false,
        viewColumns: false,
        customBodyRender: (value) => {
          return (
            <Button variant="contained" href={'/church/' + value}>
              Edit
            </Button>
          );
        },
      },
    },
    { name: 'id' },
    { name: 'status' },
    {
      name: 'name',
      options: {
        filter: false,
        setCellProps: () => ({ style: { minWidth: '300px' } }),
      },
    },
    {
      name: 'church_name_translation',
      options: {
        display: false,
        filter: false,
      },
    },
    { name: 'denomination' },
    {
      name: 'address',
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: 'city',
      options: {
        display: false,
      },
    },
    {
      name: 'province',
      options: {
        display: false,
      },
    },
    {
      name: 'postal_code',
      options: {
        display: false,
      },
    },
    {
      name: 'languages',
      options: {
        display: false,
      },
    },
    {
      name: 'url_photo',
      options: {
        display: false,
        filter: false,
        searchable: false,
      },
    },
    {
      name: 'website',
      options: {
        display: false,
        filter: false,
        searchable: false,
      },
    },
    {
      name: 'phone',
      options: {
        filter: false,
        setCellProps: () => ({ style: { minWidth: '150px' } }),
      },
    },
    {
      name: 'email',
      options: {
        filter: false,
      },
    },
    {
      name: 'contact_person_name',
      options: {
        filter: false,
      },
    },
    {
      name: 'contact_person_email',
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: 'decision_maker',
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: 'newcomer_group',
      options: {
        display: false,
      },
    },
    {
      name: 'comments',
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: 'latitude',
      options: {
        display: false,
        filter: false,
        download: false,
        print: false,
        searchable: false,
        sort: false,
        viewColumns: false,
      },
    },
    {
      name: 'longitude',
      options: {
        display: false,
        filter: false,
        download: false,
        print: false,
        searchable: false,
        sort: false,
        viewColumns: false,
      },
    },
    {
      name: 'training_onboard_date',
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: 'enrollment_approval_date',
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: 'approver_name',
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: 'notes',
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: 'portfolio_manager',
      options: {
        display: false,
      },
    },
    {
      name: 'submitter_email',
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: 'date_created',
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: 'date_modified',
      options: {
        display: false,
        filter: false,
        sortDirection: 'desc',
      },
    },
  ];

  return (
    <Layout>
      <Box textAlign="right" m={1}>
        <Button variant="contained" color="default" className={classes.button} startIcon={<AddIcon />} href={'/church'}>
          Add Church
        </Button>
        <Button variant="contained" color="default" className={classes.button} startIcon={<CloudUploadIcon />}>
          Upload
        </Button>
      </Box>

      <MUIDataTable
        title={'Church List'}
        className={classes.table}
        data={data}
        columns={columns || []}
        options={{
          responsive: 'standard',
          selectableRows: false,
          rowsPerPageOptions: [5, 10, 20, 50],
          onDownload: (buildHead, buildBody, columns, data) => {
            return '\uFEFF' + buildHead(columns) + buildBody(data);
          },
          downloadOptions: {
            filterOptions: {
              useDisplayedColumnsOnly: true,
              useDisplayedRowsOnly: true,
            },
          },
        }}
      />
    </Layout>
  );
};

export default ChurchList;

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  table: {
    marginLeft: 10,
    marginRight: 10,
    height: '100%',
  },
}));
