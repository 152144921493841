import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import Layout from './Layout';
import DropDown from './DropDown';
import TextBox from './TextBox';
import MultipleSelection from './MultipleSelection';

import { denominations, provinces, decision_maker, newcomer_group, status, languages } from '../utils/options';
import { getChurch, updateData, saveChurch } from '../store/actions/church';
import ChurchUsers from './ChurchUsers';

const Church = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { item } = useSelector((state) => state.church);
  const id = props.match.params.id;

  useEffect(() => {
    if (id) {
      dispatch(getChurch(id));
    }
  }, []);

  const handleChange = (id, value) => {
    dispatch(updateData(id, value));
  };

  const handleSave = () => {
    dispatch(saveChurch(item));
  };

  return (
    <Layout>
      <form className={classes.root} autoComplete="off">
        <Typography variant="h3" gutterBottom>
          Church Registration Form
        </Typography>
        <Paper className={classes.paper}>
          <Typography component="h2" variant="h6" color="primary" gutterBottom>
            Church Registration Details
          </Typography>
          <TextBox id="name" label="Church Name" value={item.name} required onChange={handleChange} />
          <TextBox
            id="church_name_translation"
            label="Church Name Translation"
            value={item.church_name_translation}
            onChange={handleChange}
          />
          <DropDown
            id="denomination"
            label="Denomination"
            value={item.denomination}
            options={denominations}
            helperText="Enter your denomination if not listed"
            required
            allowOtherValue
            onChange={handleChange}
          />
          <TextBox
            id="address"
            label="Address"
            value={item.address}
            multiline
            rows={3}
            required
            onChange={handleChange}
          />
          <TextBox id="city" label="City" value={item.city} required onChange={handleChange} />
          <DropDown
            id="province"
            label="Province"
            value={item.province}
            options={provinces}
            required
            onChange={handleChange}
          />
          <TextBox id="postal_code" label="Postal Code" value={item.postal_code} required onChange={handleChange} />
          <TextBox
            id="phone"
            label="Church Phone#"
            value={item.phone}
            placeholder="###-###-####"
            required
            onChange={handleChange}
            pattern="^[2-9]\d{2}-\d{3}-\d{4}$"
          />
          <TextBox id="email" label="Church Email" value={item.email} required onChange={handleChange} />
          <TextBox id="website" label="Church Website" value={item.website} required onChange={handleChange} />
          <MultipleSelection
            id="languages"
            label="Languages"
            value={item.languages}
            options={languages}
            helperText="Enter additional language if not listed"
            allowOtherValue
            required
            onChange={handleChange}
          />
          <TextBox
            id="contact_person_name"
            label="Contact Person Name"
            value={item.contact_person_name}
            helperText="First LastName"
            required
            onChange={handleChange}
          />
          <TextBox
            id="contact_person_email"
            label="Contact Person Email"
            value={item.contact_person_email}
            required
            onChange={handleChange}
          />
          <DropDown
            id="decision_maker"
            label="Decision Maker"
            value={item.decision_maker}
            options={decision_maker}
            required
            onChange={handleChange}
          />
          <MultipleSelection
            id="newcomer_group"
            label="Newcomer Group"
            value={item.newcomer_group}
            options={newcomer_group}
            helperText="Enter additional value if not listed"
            allowOtherValue
            required
            onChange={handleChange}
          />
          <TextBox id="comments" label="Comments" value={item.comments} multiline rows={3} onChange={handleChange} />
          <TextBox id="submitter_email" label="Submitter Email" value={item.submitter_email} disabled />
        </Paper>

        <Paper className={classes.paper}>
          <Typography component="h2" variant="h6" color="primary" gutterBottom>
            Administration Section
          </Typography>
          <TextBox
            id="portfolio_manager"
            label="Portfolio Manager"
            value={item.portfolio_manager}
            onChange={handleChange}
          />
          <DropDown id="status" label="Status" value={item.status} options={status} required onChange={handleChange} />
          <TextBox id="latitude" label="Church Location Latitude" value={item.latitude} onChange={handleChange} />
          <TextBox id="longitude" label="Church Location Longitude" value={item.longitude} onChange={handleChange} />
          <TextBox
            id="url_photo"
            label="Church Photo Link"
            value={item.url_photo}
            multiline
            rows={3}
            onChange={handleChange}
          />
          <TextBox
            id="training_onboard_date"
            label="Training Onboard Date"
            value={item.training_onboard_date}
            type="date"
            onChange={handleChange}
          />
          <TextBox
            id="enrollment_approval_date"
            label="Enrollment Approval Date"
            value={item.enrollment_approval_date}
            type="date"
            onChange={handleChange}
          />
          <TextBox id="approver_name" label="Approver Name" value={item.approver_name} onChange={handleChange} />
          <TextBox id="notes" label="Notes" value={item.notes} multiline rows={3} onChange={handleChange} />
        </Paper>
        <Paper className={classes.paper}>
          <Typography component="h2" variant="h6" color="primary" gutterBottom>
            Audit Logs
          </Typography>
          <TextBox id="date_created" label="Date Created" value={item.date_created} disabled />
          <TextBox id="created_by" label="Created By" value={item.created_by} disabled />
          <TextBox id="date_modified" label="Date Modified" value={item.date_modified} disabled />
          <TextBox id="modified_by" label="Modified By" value={item.modified_by} disabled />
        </Paper>
        <Paper className={classes.paper}>
          <Typography component="h2" variant="h6" color="primary" gutterBottom>
            Church Users
          </Typography>
          <ChurchUsers
            church_id={item.church_id}
            defaultEmail={item.contact_person_email}
            defaultName={item.contact_person_name}
            users={item.users}
          />
        </Paper>
        <Box display="flex" justifyContent="center" m={1} p={1}>
          <Button variant="contained" color="primary" className={classes.button} onClick={handleSave}>
            Save Changes
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => (window.location.href = '/churchlist')}
          >
            Close
          </Button>
        </Box>
      </form>
    </Layout>
  );
};

export default Church;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),

    '& .MuiTypography-gutterBottom': {
      marginBottom: '30px',
    },
    '& .MuiFormControl-fullWidth': {
      marginBottom: '30px',
    },
    '& .MuiFormGroup-root .MuiFormControlLabel-root': {
      width: '350px',
    },
  },
  paper: {
    padding: theme.spacing(4),
    margin: 'auto',
    marginBottom: 20,
    maxWidth: 500,
  },
  formControl: {
    marginTop: 20,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: 20,
    marginRight: 20,
    width: 150,
  },
}));
