import React from 'react';

export const validationData = (item, validations) => {
  let requiredFields = [];
  Object.keys(validations).forEach((id) => {
    const { label, required } = validations[id];
    if (required && (item[id] === undefined || item[id] === null || item[id] == '')) {
      requiredFields.push(label);
    }
    // TODO: Add more validation logic here...
  });

  if (requiredFields.length > 0)
    return (
      <>
        <span>The following field(s) are required:</span>
        <ul>
          {requiredFields.map((a) => (
            <li key={a}>{a}</li>
          ))}
        </ul>
      </>
    );
};
