import React from 'react';
import { useDispatch } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { checkValidations } from '../store/actions/common';

const DropDown = ({ id, label, value, options, helperText, required, allowOtherValue, onChange }) => {
  const dispatch = useDispatch();
  if (required) dispatch(checkValidations(id, { label, required: true }));

  const handleChange = (e, value) => {
    onChange && onChange(id, value);
  };

  return (
    <Autocomplete
      id={id}
      value={value || ''}
      options={options}
      freeSolo={allowOtherValue}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          helperText={helperText}
          required={required}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  );
};

export default DropDown;
