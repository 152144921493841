export const apiBaseUrl = () => {
  if (
    window.location.href.includes('https://www.canwelcomechurches.ca') ||
    window.location.href.includes('https://canwelcomechurches.ca')
  )
    return 'https://jvhwnq2a3e.execute-api.ap-southeast-1.amazonaws.com/api';

  if (window.location.href.includes('staging.'))
    return 'https://vn19ng0tb6.execute-api.ap-southeast-1.amazonaws.com/beta';

  return 'https://2x8o3g3o85.execute-api.ap-southeast-1.amazonaws.com/dev';
};
