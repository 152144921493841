import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import '@fontsource/roboto';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
//import store from './redux/store';
import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import userReducer from './store/reducers/user';
import churchReducer from './store/reducers/church';
import commonReducer from './store/reducers/common';

const rootReducer = combineReducers({
  user: userReducer,
  church: churchReducer,
  common: commonReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
