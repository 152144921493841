import { SIGN_IN, CREATE_USER, VERIFY_ACCOUNT } from '../actions/user';

const initialState = {
  success: undefined,
  email: undefined,
  token: undefined,
};

const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SIGN_IN:
      return { ...state, email: payload.email, token: payload.token, success: true };
    case CREATE_USER:
      return { ...state, ...payload, success: true };
    case VERIFY_ACCOUNT:
      return { ...state, ...payload, success: true };
    default:
      return state;
  }
};

export default userReducer;
