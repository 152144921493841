import { SET_ERROR, IS_LOADING, SHOW_DIALOG, HIDE_DIALOG, CHECK_VALIDATIONS } from '../actions/common';

const initialState = {
  loading: false,
  error: '',
  dialog: { open: false },
  validations: {},
};

const commonReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ERROR:
      return { ...state, loading: false, error: payload.message };
    case IS_LOADING:
      return { ...state, loading: true };
    case SHOW_DIALOG:
      return { ...state, dialog: payload };
    case HIDE_DIALOG:
      return { ...state, dialog: { open: false } };
    case CHECK_VALIDATIONS: {
      let items = { ...state.validations };
      items[payload.id] = payload.params;
      return { ...state, validations: items };
    }
    default:
      return state;
  }
};

export default commonReducer;
