import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';

import { checkValidations } from '../store/actions/common';

const TextBox = ({
  id,
  label,
  value,
  multiline,
  rows,
  required,
  disabled,
  type,
  helperText,
  placeholder,
  pattern,
  onChange,
}) => {
  const [errorText, setErrorText] = useState();
  const dispatch = useDispatch();
  if (required) dispatch(checkValidations(id, { label, required: true }));

  const handleChange = (e) => {
    onChange && onChange(id, e.target.value);
  };

  const handleBlur = (e) => {
    if (pattern && e.target.value) {
      if (e.target.value.match(pattern)) {
        setErrorText(undefined);
      } else {
        setErrorText('Invalid format');
      }
    }
  };

  return (
    <TextField
      id={id}
      label={label}
      value={value || ''}
      placeholder={placeholder}
      multiline={multiline}
      rows={rows}
      required={required}
      disabled={disabled}
      type={type}
      helperText={errorText || helperText}
      onChange={handleChange}
      onBlur={handleBlur}
      fullWidth
      error={!!errorText}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export default TextBox;
