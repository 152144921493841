import axios from 'axios';
import * as constants from '../../utils/constants';
import { apiBaseUrl } from '../../utils/config';
import * as common from '../actions/common';
import * as utils from '../../utils/utils';

export const GET_CHURCH_LIST = 'GET_CHURCH_LIST';
export const GET_CHURCH = 'GET_CHURCH';
export const UPDATE_DATA = 'UPDATE_DATA';

export const getChurchList = () => {
  return async (dispatch) => {
    try {
      dispatch(common.setLoading(true));

      const token = localStorage.getItem(constants.TOKEN);
      const response = await axios.get(`${apiBaseUrl()}/churches`, {
        headers: { Authorization: token },
      });

      dispatch({ type: GET_CHURCH_LIST, payload: response.data });
    } catch (error) {
      dispatch(common.setError(error));
    }
  };
};

export const getChurch = (id) => {
  return async (dispatch) => {
    try {
      dispatch(common.setLoading(true));

      const token = localStorage.getItem(constants.TOKEN);
      const response = await axios.get(`${apiBaseUrl()}/churches/${id}`, {
        headers: { Authorization: token },
      });

      let data = response.data;
      const multipleSelection = ['languages', 'newcomer_group'];
      multipleSelection.forEach((a) => (data.record[a] = data.record[a] ? data.record[a].split(',') : []));

      const { contact_person_name, contact_person_email } = data.record;
      const users = data.record.users || [];
      const contact = users.find((a) => a.email === contact_person_email);

      if (contact === undefined) {
        data.record['account_name'] = contact_person_name;
        data.record['account_email'] = contact_person_email;
      }

      dispatch({ type: GET_CHURCH, payload: data });
    } catch (error) {
      dispatch(common.setError(error));
    }
  };
};

export const saveChurch = (item) => {
  return async (dispatch, getState) => {
    try {
      const validation = utils.validationData(getState().church.item, getState().common.validations);
      if (validation) {
        dispatch(
          common.showDialog({
            content: validation,
            buttonOkCallback: () => {},
          }),
        );
        return;
      }

      dispatch(
        common.showDialog({
          content: 'Are you sure you want to save?',
          buttonYesCallback: async () => {
            dispatch(common.setLoading(true));

            let data = {
              ...item,
              languages: item.languages.join(','),
              newcomer_group: item.newcomer_group.join(','),
            };
            const token = localStorage.getItem(constants.TOKEN);

            const response = await axios.post(`${apiBaseUrl()}/churches`, data, {
              headers: { Authorization: token },
            });

            dispatch(
              common.showDialog({
                content: response.data.message,
                buttonOkCallback: () => (window.location.href = '/churchlist'),
              }),
            );
          },
        }),
      );
    } catch (error) {
      dispatch(common.setError(error));
    }
  };
};

export const registerChurch = (item) => {
  return async (dispatch, getState) => {
    const title = 'Welcome Church Registration';

    try {
      const validation = utils.validationData(getState().church.item, getState().common.validations);

      if (validation) {
        dispatch(
          common.showDialog({
            title,
            content: validation,
            buttonOkCallback: () => {},
          }),
        );
        return;
      }

      dispatch(
        common.showDialog({
          title,
          content: 'Are you sure you want to submit?',
          buttonYesCallback: async () => {
            dispatch(common.setLoading(true));

            let data = {
              ...item,
              languages: item.languages.join(','),
              newcomer_group: item.newcomer_group.join(','),
            };

            const response = await axios.post(`${apiBaseUrl()}/churches/registration`, data);

            dispatch(
              common.showDialog({
                title,
                content: response.data.message,
                buttonOkCallback: () => (window.location.href = '/churchregistration'),
              }),
            );
          },
        }),
      );
    } catch (error) {
      dispatch(common.setError(error));
    }
  };
};

export const updateData = (id, value) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_DATA, payload: { id, value } });
  };
};
