import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import TextBox from './TextBox';
import { createUser } from '../store/actions/user';
import { updateData } from '../store/actions/church';

const ChurchUsers = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { item } = useSelector((state) => state.church);
  const { loading } = useSelector((state) => state.common);

  const handleChange = (id, value) => {
    dispatch(updateData(id, value));
  };

  const handleSave = () => {
    dispatch(createUser(item.account_email, item.account_name, item.id));
  };

  const items = item.users == null ? [] : item.users;

  return (
    <>
      <TextBox
        id="account_name"
        label="Name"
        value={item.account_name}
        helperText="First LastName"
        onChange={handleChange}
      />
      <TextBox id="account_email" label="Email" value={item.account_email} onChange={handleChange} />
      <Button variant="contained" color="primary" className={classes.buttonCreateAccount} onClick={handleSave}>
        Create Account
      </Button>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell align="right">Name</TableCell>
              <TableCell align="right">Verified</TableCell>
              <TableCell align="right">Date Created</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.email}
                </TableCell>
                <TableCell align="right">{row.name}</TableCell>
                <TableCell align="right">{row.verified == 1 ? 'Y' : 'N'}</TableCell>
                <TableCell align="right">{row.date_created}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ChurchUsers;

const useStyles = makeStyles((theme) => ({
  buttonCreateAccount: {
    marginBottom: 20,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));
