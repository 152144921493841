import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import * as constants from '../utils/constants';
import { hideDialog } from '../store/actions/common';

const DialogBox = () => {
  const dispatch = useDispatch();
  const { title, content, open, buttonOkCallback, buttonYesCallback } = useSelector((state) => state.common.dialog);

  const handleClose = () => {
    dispatch(hideDialog());
  };

  const handleOk = () => {
    dispatch(hideDialog());
    buttonOkCallback && buttonOkCallback();
  };

  const handleYes = () => {
    dispatch(hideDialog());
    buttonYesCallback && buttonYesCallback();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title || constants.TITLE}</DialogTitle>
      <DialogContent>
        {React.isValidElement(content) ? (
          content
        ) : (
          <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        {buttonYesCallback && (
          <>
            <Button onClick={handleYes} color="primary">
              Yes
            </Button>
            <Button onClick={handleClose} color="primary" autoFocus>
              Cancel
            </Button>
          </>
        )}
        {buttonOkCallback && (
          <Button onClick={handleOk} color="primary" autoFocus>
            Ok
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DialogBox;
