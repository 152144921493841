import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import * as constants from '../utils/constants';

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem(constants.TOKEN) ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/signin', state: { from: props.location } }} />
      )
    }
  />
);
