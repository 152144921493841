import axios from 'axios';
import * as constants from '../../utils/constants';
import { apiBaseUrl } from '../../utils/config';
import * as common from '../actions/common';

export const SIGN_IN = 'SIGN_IN';
export const CREATE_USER = 'CREATE_USER';
export const VERIFY_ACCOUNT = 'VERIFY_ACCOUNT';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const CONFIRM_PASSWORD = 'CONFIRM_PASSWORD';

export const signIn = (username, password) => {
  return async (dispatch) => {
    try {
      dispatch(common.setLoading(true));

      const response = await axios.post(`${apiBaseUrl()}/auth/signin`, {
        username,
        password,
      });

      const { token, type } = response.data;
      localStorage.setItem(constants.TOKEN, token);
      localStorage.setItem(constants.USER_TYPE, type);
      dispatch({ type: SIGN_IN, payload: response.data });
    } catch (error) {
      localStorage.removeItem(constants.TOKEN);
      localStorage.removeItem(constants.USER_TYPE);
      dispatch(common.setError(error));
    }
  };
};

export const createUser = (username, name, church_id) => {
  return async (dispatch) => {
    dispatch(
      common.showDialog({
        title: 'Account Creation',
        content: 'Are you sure you want to create the user?',
        buttonYesCallback: async () => {
          dispatch(common.setLoading(true));

          try {
            const token = localStorage.getItem(constants.TOKEN);
            const response = await axios.post(
              `${apiBaseUrl()}/user/create`,
              {
                username,
                name,
                church_id,
              },
              {
                headers: { Authorization: token },
              },
            );

            dispatch({ type: CREATE_USER, payload: response.data });

            dispatch(
              common.showDialog({
                content: response.data.message,
                buttonOkCallback: () => window.location.reload(),
              }),
            );
          } catch (error) {
            dispatch(common.setError(error));
          }
        },
      }),
    );
  };
};

export const verifyAccount = (username, code, existingPassword, newPassword) => {
  return async (dispatch) => {
    try {
      dispatch(common.setLoading(true));

      const response = await axios.post(`${apiBaseUrl()}/user/verify`, {
        username,
        code,
        existingPassword,
        newPassword,
      });

      dispatch({ type: VERIFY_ACCOUNT, payload: response.data });

      dispatch(
        common.showDialog({
          content: response.data.message,
          buttonOkCallback: () => (window.location.href = '/signin'),
        }),
      );
    } catch (error) {
      dispatch(common.setError(error));
    }
  };
};

export const resetPassword = (username) => {
  return async (dispatch) => {
    try {
      dispatch(common.setLoading(true));

      const response = await axios.get(`${apiBaseUrl()}/user/resetpassword/${username}`);

      dispatch({ type: RESET_PASSWORD, payload: response.data });

      dispatch(
        common.showDialog({
          content: response.data.message,
          buttonOkCallback: () => (window.location.href = '/confirmpassword'),
        }),
      );
    } catch (error) {
      dispatch(common.setError(error));
    }
  };
};

export const confirmPassword = (username, code, newPassword) => {
  return async (dispatch) => {
    try {
      dispatch(common.setLoading(true));

      const response = await axios.post(`${apiBaseUrl()}/user/confirmpassword`, {
        username,
        code,
        newPassword,
      });

      dispatch({ type: CONFIRM_PASSWORD, payload: response.data });

      dispatch(
        common.showDialog({
          content: response.data.message,
          buttonOkCallback: () => (window.location.href = '/signin'),
        }),
      );
    } catch (error) {
      dispatch(common.setError(error));
    }
  };
};
