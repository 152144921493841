export const SET_ERROR = 'SET_ERROR';
export const IS_LOADING = 'IS_LOADING';
export const SHOW_DIALOG = 'SHOW_DIALOG';
export const HIDE_DIALOG = 'HIDE_DIALOG';
export const CHECK_VALIDATIONS = 'CHECK_VALIDATIONS';

export const setLoading = (loading) => {
  return (dispatch) => {
    dispatch({ type: IS_LOADING, payload: { loading } });
  };
};

export const setError = (error) => {
  return (dispatch) => {
    let message = error.message;
    if (error.response) message = error.response.data.message;

    dispatch({ type: SET_ERROR, payload: { message } });

    if (message === 'Network Error')
      dispatch(
        showDialog({
          content: 'Your session expired and need to login again.',
          buttonOkCallback: () => (window.location.href = '/signin'),
        }),
      );
    else dispatch(showDialog({ content: message, buttonOkCallback: () => {} }));
  };
};

export const showDialog = (dialog) => {
  return (dispatch) => {
    dispatch({ type: SHOW_DIALOG, payload: { ...dialog, open: true } });
  };
};

export const hideDialog = () => {
  return (dispatch) => {
    dispatch({ type: HIDE_DIALOG });
  };
};

export const checkValidations = (id, params) => {
  return (dispatch) => {
    dispatch({ type: CHECK_VALIDATIONS, payload: { id, params } });
  };
};
