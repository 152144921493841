import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import MediaCard from './MediaCard';

import list from '../assets/data/training_resources.json';
import Layout from './Layout';

const TrainingResources = () => {
  const classes = useStyles();

  const items = list;

  return (
    <Layout>
      <Typography variant="h3" gutterBottom>
        Preparing to Welcome Newcomers
      </Typography>

      <div className={classes.root}>
        <Grid container spacing={3}>
          {items.map((item, i) => (
            <Grid key={i} item>
              <MediaCard {...item} />
            </Grid>
          ))}
        </Grid>
      </div>
    </Layout>
  );
};

export default TrainingResources;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),

    '& .MuiTypography-gutterBottom': {
      marginBottom: '30px',
    },
    '& .MuiFormControl-fullWidth': {
      marginBottom: '30px',
    },
    '& .MuiFormGroup-root .MuiFormControlLabel-root': {
      width: '350px',
    },
  },
}));
