import { GET_CHURCH_LIST, GET_CHURCH, UPDATE_DATA } from '../actions/church';

const initialState = {
  items: [],
  item: {},
};

const churchReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_CHURCH_LIST:
      return { ...state, items: payload.records };
    case GET_CHURCH:
      return { ...state, item: payload.record };
    case UPDATE_DATA: {
      let item = { ...state.item };
      item[payload.id] = payload.value;
      return { ...state, item: item };
    }
    default:
      return state;
  }
};

export default churchReducer;
