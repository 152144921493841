export const denominations = [
  '',
  'African Protestant/Zion Christian Church',
  'Anabaptist',
  'Anglican',
  'Baptist',
  'Catholic',
  'Church of the East/Assyrian Church',
  'Congregational',
  'Eastern Orthodox',
  'Lutheran',
  'Methodist',
  'New Apostolic Church',
  'Non-Denominational/Evangelical',
  'Oriental Orthodox',
  'Pentecostal',
  'Quaker/Friends',
  'Reformed',
  'Restoration',
];

export const provinces = ['', 'NL', 'PE', 'NS', 'NB', 'QC', 'ON', 'MB', 'SK', 'AB', 'BC', 'YT', 'NT', 'NU'];

export const newcomer_group = ['Hong Kong newcomers', 'Immigrant newcomers', 'Refugees', 'International students'];

export const decision_maker = ['', 'Yes', 'No'];

export const status = ['', 'New', 'Duplicate', 'Verified', 'Active', 'Inactive', 'Closed'];

export const languages = ['English', 'Cantonese', 'Mandarin', 'French', 'Spanish', 'Hindi'];
