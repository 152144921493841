import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import DropDown from './DropDown';
import TextBox from './TextBox';
import MultipleSelection from './MultipleSelection';
import DialogBox from './DialogBox';

import { denominations, provinces, decision_maker, newcomer_group, languages } from '../utils/options';
import { registerChurch, updateData } from '../store/actions/church';

const ChurchRegistration = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { item } = useSelector((state) => state.church);

  const handleChange = (id, value) => {
    dispatch(updateData(id, value));
  };

  const handleSave = () => {
    dispatch(registerChurch(item));
  };

  return (
    <>
      <DialogBox />
      <form className={classes.root} autoComplete="off">
        <Typography component="h2" variant="h6" color="primary" gutterBottom>
          Church Registration Form
        </Typography>
        <Paper className={classes.paper}>
          <TextBox id="name" label="Church Name" value={item.name} required onChange={handleChange} />
          <TextBox
            id="church_name_translation"
            label="Church Name Translation"
            value={item.church_name_translation}
            onChange={handleChange}
          />
          <DropDown
            id="denomination"
            label="Denomination"
            value={item.denomination}
            options={denominations}
            helperText="Enter your denomination if not listed"
            required
            allowOtherValue
            onChange={handleChange}
          />
          <TextBox
            id="address"
            label="Address"
            value={item.address}
            multiline
            rows={3}
            required
            onChange={handleChange}
          />
          <TextBox id="city" label="City" value={item.city} required onChange={handleChange} />
          <DropDown
            id="province"
            label="Province"
            value={item.province}
            options={provinces}
            required
            onChange={handleChange}
          />
          <TextBox id="postal_code" label="Postal Code" value={item.postal_code} required onChange={handleChange} />
          <TextBox
            id="phone"
            label="Church Phone#"
            value={item.phone}
            placeholder="###-###-####"
            required
            onChange={handleChange}
            pattern="^[2-9]\d{2}-\d{3}-\d{4}$"
          />
          <TextBox id="email" label="Church Email" value={item.email} required onChange={handleChange} />
          <TextBox id="website" label="Church Website" value={item.website} required onChange={handleChange} />
          <MultipleSelection
            id="languages"
            label="Languages"
            value={item.languages}
            options={languages}
            helperText="Enter additional language if not listed"
            allowOtherValue
            required
            onChange={handleChange}
          />
          <TextBox
            id="contact_person_name"
            label="Contact Person Name"
            value={item.contact_person_name}
            helperText="First LastName"
            required
            onChange={handleChange}
          />
          <TextBox
            id="contact_person_email"
            label="Contact Person Email"
            value={item.contact_person_email}
            required
            onChange={handleChange}
          />
          <DropDown
            id="decision_maker"
            label="Decision Maker"
            value={item.decision_maker}
            options={decision_maker}
            required
            onChange={handleChange}
          />
          <MultipleSelection
            id="newcomer_group"
            label="Newcomer Group"
            value={item.newcomer_group}
            options={newcomer_group}
            helperText="Enter additional value if not listed"
            allowOtherValue
            required
            onChange={handleChange}
          />
          <TextBox id="comments" label="Comments" value={item.comments} multiline rows={3} onChange={handleChange} />
          <TextBox
            id="submitter_email"
            label="Submitter Email"
            value={item.submitter_email}
            required
            onChange={handleChange}
          />
        </Paper>

        <Box display="flex" justifyContent="center" m={1} p={1}>
          <Button variant="contained" color="primary" className={classes.button} onClick={handleSave}>
            Submit
          </Button>
        </Box>
      </form>
    </>
  );
};

export default ChurchRegistration;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),

    '& .MuiTypography-gutterBottom': {
      marginBottom: '30px',
    },
    '& .MuiFormControl-fullWidth': {
      marginBottom: '30px',
    },
    '& .MuiFormGroup-root .MuiFormControlLabel-root': {
      width: '350px',
    },
  },
  paper: {
    padding: theme.spacing(4),
    margin: 'auto',
    marginBottom: 20,
    maxWidth: 500,
  },
  formControl: {
    marginTop: 20,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: 20,
    marginRight: 20,
    width: 150,
  },
}));
